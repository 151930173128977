import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { DEMANDE_STATUS } from '../GLOBAL_VARIABLE';
import { t } from 'i18next';
import UseCinetPay from '../hooks/useCinetPay';
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { formatMoney } from '../utils';
import { useSelector } from 'react-redux';
const API_URL = `${process.env.REACT_APP_API_URL}/`;

export default function ModalFormStartPay({ setMessageModal, data, endFetch }) {
    console.log("🚀 ~ ModalFormStartPay ~ data:", data)
    const API_URL = `${process.env.REACT_APP_API_URL}/`;
    const defaultLayoutPluginInstance = defaultLayoutPlugin()
    const [payMode, setPayMode] = React.useState('')
    const { user: currentUser } = useSelector((state) => state.auth);

    const [open, setOpen] = React.useState(false);
    const [isfecEndded, setisfecEndded] = React.useState(true);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [res, setRes] = React.useState({})
    const [errors, setErrors] = React.useState({ amout: "", proof: "" })
    const [proof, setProof] = useState(null);
    const [loadp, errorp, startPayment] = UseCinetPay();
    const [amount, setAmount] = useState(null);
    console.log("🚀 ~ amount:", amount)
 
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [fileSizeError, setFileSizeError] = useState('');
    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        setErrors({ proof: '' })


        if (uploadedFile && uploadedFile.size > maxFileSize) {
            setFileSizeError(t('evaluation.Error file size'));
            setErrors({ proof: t('evaluation.Error file size') })

            setProof(null); // Clear file selection
        } else {
            setProof(uploadedFile);
            setFileSizeError(''); // Clear any previous error message
        }
    };

    const generatePaymentReference = ()=> {
        const timestamp = Date.now().toString(); // Timestamp pour l'unicité
        const random = Math.floor(Math.random() * 100000).toString(); // Nombre aléatoire
        return `CINETPAY_${timestamp}_${random}`; // Ex: CINETPAY_1688827691234_45678
      }

   
    const handleSend = () => {
        console.log("🚀 ~ handleSend ~ amount:", amount)
        console.log("🚀 ~ handleSend ~ proof:", proof)
        let ers = {  }
        if (amount == null || amount == undefined || amount == "") {
            ers = ({ ...ers, amout: t("Ce champ est requis") })
            
        }
        if (proof == null || proof == undefined) {
            ers = ({...ers, proof: t("Ce champ est requis") })
        }
        if(Object.values(ers)?.length > 0){
            setErrors(ers)
            return
        }
        const ref = generatePaymentReference();
        let formdata = new FormData();
        formdata.append("recu", proof);
        formdata.append("pay_amount", amount);
        formdata.append("ref", ref);
        // axios
        //     .post(
        //         API_URL + "proof-of-paiement",
        //         formdata
        //         ,
        //         {
        //             headers: {
        //                 "Content-Type": "application/json",
        //             },
        //         }
        //     )
        //     .then((response) => {
        //         console.log("response", response);
        //         setLoading(false);
        //         if (response.status == 200) {

        //         } else {

        //         }
        //     })
        //     .catch((e) => {
        //         setLoading(false);
        //         setMessage("Internal error. Please try again later");
        //         setError(true);
        //         toast.error("Internal error. Please try again later")
        //         return null;
        //     });
        console.log("🚀 ~ handleSend ~ formdata:", formdata)
        setLoading(true);

        axios
            .post(API_URL + "demandeVisas/pay/" + data?.id, formdata, {
                headers: {
                    "content-type": "multipart/form-data",
                    Authorization: `Bearer ${currentUser.token}`,
                },
            })
            .then((response) => {
                console.log("response", response);
                startPayment({ref: "dwefw", amount: data?.decided_fees });
                //   if (response.ok) {
                toast.success(response?.data?.message);
                // history.navigate("/dashboard");
                setOpen(false)
                endFetch()
                // startPay(data);
                //   } else {
                //     toast.error(response?.data?.message);
                //   }
                //   return response;
            })
            .catch((e) => {
                // alert(e?.code =='');
                //   setSuccessful(false);
                toast.error(e?.code == 'ERR_NETWORK' ? t('Problème de conexion') : "Sorry and error occured. Please try against");

                //   return null;
            })
            .finally(() => setLoading(false))
            ;
    }

    return (
        <div>
            <button type="button" className="dropdown-item" onClick={handleClickOpen}>
                {/* Pay Here */}
                {/* {DEMANDE_STATUS.VALIDATED.label} */}
                {/* {t('payHere')} */}
                {t('evaluation.See evaluation and Submit proof of payment')}
            </button>
            {/* {loading ? <div id="preloader"></div> : <> </>} */}
            <Dialog open={open} fullWidth="md" onClose={handleClose}>
                <Row className='row-cols-1'>
                    <Col className='d-none'>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <div
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.3)',
                                    height: '100%',
                                    overflow: 'auto'

                                }}
                            >
                                {/* {console.log("🚀 ~ ModalFormStartPay ~ data?.recu:", data?.recu)} */}
                                {/* { data?.evaluationDoc && <Viewer fileUrl={data?.evaluationDoc}  */}
                                {data?.evaluationDoc && <Viewer fileUrl={data?.evaluationDoc}
                                // <Viewer fileUrl={data?.recu}
                                // plugins={[
                                //     defaultLayoutPluginInstance,
                                // ]}
                                />}
                            </div>
                        </Worker>

                    </Col>
                    <Col>
                        <form className='shadow mt-5 rounded' >

                            <DialogTitle>  <div className='d-flex flex-column justify-content-center align-items-center my-3'>
                                <div className='d-flex align-items-center justify-content-center shadow py-2 px-4 rounded'>
                                    <p className='m-0'>{t('evaluation.Evaluation Amount')} :</p>
                                    <p className='badge badge-info fs-6 m-0 ms-2 p-3'>{formatMoney(data?.decided_fees)} {t("evaluation.XAF")}</p>
                                </div>

                                <div>

                                    <a
                                        // href={data?.recu} 
                                        href={data?.evaluationDoc}
                                        //  href={visa?.doc}
                                        download
                                        target='_blank'
                                        className='btn btn-success shadow mt-2'>
                                        {t("evaluation.Download evaluation")} <i className='bi bi-filetype-pdf' />
                                    </a>
                                </div>
                            </div>
                            </DialogTitle>
                            {(res?.success === false || fileSizeError) && <div className='alert alert-danger text-center' role='alert'> {res?.message || fileSizeError} </div>}
                            <DialogContent>
                                {/* 
                        <div className='d-flex justify-content-between my-3'>
                            <div className='form-group'>
                                <Form.Group>
                                    <label>{t('Evaluation Amount')} :</label>
                                    <p className='badge badge-info fs-6 ms-2 p-3'>1400000000 XAF</p>
                                </Form.Group>
                            </div>
                            <div>

                                <a
                                    href='#'
                                    //  href={visa?.doc}
                                    //  download={visa?.demande_id}
                                    target='_blank' className='btn btn-success shadow '>
                                    View review <i className='bi bi-filetype-pdf' />
                                </a>
                            </div>
                        </div> */}

                                <div className='form-group '>
                                    <Form.Group>
                                        <label>{t('evaluation.Permit fees (in XAF)')} <span className='text-danger'>*</span></label>
                                        <Form.Control className="shadow-sm"
                                            id="amount"
                                            name="amount"
                                            type="number"
min={0}
                                            disabled={loading}
                                            onChange={(e) => setAmount(e?.target?.value)}

                                        ></Form.Control>
                                        {errors?.amout && <span className='text-danger'>{errors?.amout}</span>}
                                    </Form.Group>

                                </div>

                                <div className='form-group'>

                                    <Form.Group>
                                        <label>{t('evaluation.Submit proof of payment (pdf only)')} <span className='text-danger'>*</span></label>
                                        <Form.Control className={`shadow-sm ${fileSizeError ? 'border border-danger' : ''}`}
                                            id="proof"
                                            name="proof"
                                            type="file"

                                            onChange={handleFileChange}
                                            accept=".pdf"
                                            disabled={loading}


                                        ></Form.Control>
                                        {errors?.proof && <span className='text-danger'>{errors?.proof}</span>}

                                    </Form.Group>
                                </div>

                                {/* <div className='form-group'>

                            <label>Choose payment method <span className='text-danger'>*</span> </label>

                            <select className={`form-select shadow-sm ${errors?.payMode && 'border-danger text-danger'}`} onChange={(e) => setPayMode(e.target.value)}>
                                <option value="" selected>
                                    --Choose---
                                </option>
                                <option value="orangeMoney" >
                                    Orange Money
                                </option>
                                <option value="mtnMoney" >
                                    MTN Money
                                </option>
                                <option value="expressUnion">
                                    Express Union
                                </option>
                                <option value="visaCard">
                                    Visa Card
                                </option>
                            </select>
                            <p className='text-danger'> {errors?.payMode}</p>
                        </div> */}

                            </DialogContent>
                            <DialogActions className='d-flex justify-content-between'>
                                <Button variant='outlined' color='error' onClick={handleClose}>{t("foreignworkers.addEmployer.buttons.close")}</Button>
                                {loading ? <div class="spinner-border text-info" role="status">
                                    <span class="visually-hidden">{t("Loading")}...</span>
                                </div> :
                                    <Button
                                        disabled={fileSizeError ? true : false}

                                        variant='outlined' color='success' onClick={() => handleSend()}>{t("evaluation.Submit")}
                                        <i className='bi bi-upload ms-2' />
                                    </Button>}
                            </DialogActions>
                        </form>
                    </Col>
                </Row>
            </Dialog>
        </div>
    );
}