export const config = {
  app: {
    // api_url: "http://192.168.1.164:8000/api",
    // api_url: "https://api.eworkpermit.cm/api",
    // api_url: "https://lymo-api.herokuapp.com/api",
    // api_url: "http://localhost:3300/api",
    // fntec_url: "https://api.fntec.cm/api",
    fntec_url: "https://apitest.fntec.cm/api",
    // fntec_url: "http://testfntec-api.alshadowsgroup.com/api",
    // fntec_url: "http://localhost:8070/api",
  },
  fntec: {
    phone: "633221100",
    password: "123456789"
  },
  CINET: {
    API_KEY: "441926940628af072c360e7.68632980",
    APP_ID: "914426",
},
};
